import { useRef } from "react";
import Batches from "../layouts/Batches/Batches";
import AboutUs from "../layouts/Footer/AboutUs";
import Gallery from "../layouts/Gallery/Gallery";
import Header from "../layouts/Header/Header";
import PreviewImage from "../layouts/PreviewImage/PreviewImage";
import Services from "../layouts/Services/Services";
import Testimonials from "../layouts/Testimonials/Testimonials";
import WhyUs from "../layouts/WhyUs/WhyUs";
import MenuModal from "../layouts/modals/Menu-Modal/MenuModal";
import getLandingPageData from "../../services/helper/get_landing_page_data";

export default function BetaHome() {
  const landingPageData = getLandingPageData(window.pageData);
  // const [loading, setLoading] = useState(!landingPageData);
  const loading = !landingPageData;

  const {
    welcomeSection,
    business,
    socialLinks,
    servicesList,
    testimonialsList,
    whyUsList,
    gallery,
  } = landingPageData;

  const menuModalRef = useRef(null);
  const servicesRef = useRef(null);
  const batchesRef = useRef(null);
  const galleryRef = useRef(null);
  const testimonialRef = useRef(null);

  function handleScroll(ref) {
    menuModalRef.current.close();
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <MenuModal
        ref={menuModalRef}
        servicesScroll={() => handleScroll(servicesRef)}
        galleryScroll={() => handleScroll(galleryRef)}
        testimonialScroll={() => handleScroll(testimonialRef)}
        batchesScroll={() => handleScroll(batchesRef)}
        onClose={() => menuModalRef.current.close()}
      />
      <main>
        {loading ? (
          <div className="loading">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <Header
              businessDetails={business}
              servicesScroll={() => handleScroll(servicesRef)}
              galleryScroll={() => handleScroll(galleryRef)}
              testimonialScroll={() => handleScroll(testimonialRef)}
              batchesScroll={() => handleScroll(batchesRef)}
              onOpen={() => menuModalRef.current.showModal()}
            />

            <PreviewImage welcomeSectionDetails={welcomeSection} />

            {servicesList.length > 0 && (
              <Services ref={servicesRef} servicesList={servicesList} />
            )}

            {whyUsList.length > 0 && <WhyUs whyUsList={whyUsList} />}

            <Batches ref={batchesRef} />

            {gallery.length > 0 && (
              <Gallery ref={galleryRef} photosList={gallery} />
            )}

            {testimonialsList.length > 0 && (
              <Testimonials
                ref={testimonialRef}
                testimonialsList={testimonialsList}
              />
            )}

            <AboutUs businessDetails={business} socialLinks={socialLinks} />
          </>
        )}
      </main>
    </>
  );
}
