import styles from "./Gallery.module.css";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

import { useEffect, useState, useRef, forwardRef } from "react";

const Gallery = forwardRef(function Gallery({ photosList }, ref) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);

  const timer = useRef(null);

  useEffect(() => {
    timer.current =
      autoPlay &&
      setTimeout(() => {
        slideRight();
      }, 4000);
  });

  function slideLeft() {
    if (currentIndex === 0) {
      setCurrentIndex(photosList.length - 1);
    } else {
      setCurrentIndex((prevIndex) => {
        return prevIndex - 1;
      });
    }
  }

  function slideRight() {
    if (currentIndex === photosList.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex((prevIndex) => {
        return prevIndex + 1;
      });
    }
  }

  return (
    <div
      ref={ref}
      className={styles.gallery}
      onMouseEnter={() => {
        setAutoPlay(false);
        clearTimeout(timer.current);
      }}
      onMouseLeave={() => setAutoPlay(true)}
    >
      <h2>Gallery</h2>
      <div className={styles.carouselWrapper}>
        {photosList.map((image, index) => {
          return (
            <div
              key={`image-${index}`}
              className={`${styles.carouselCard} ${
                index === currentIndex ? styles.carouselCardActive : ""
              }`}
            >
              <img src={image} alt="" />
            </div>
          );
        })}
        <div className={styles.arrow}>
          <div className={styles.leftArrow} onClick={slideLeft}>
            <IoIosArrowBack color="white" />
          </div>
          <div className={styles.rightArrow} onClick={slideRight}>
            <IoIosArrowForward color="white" />
          </div>
        </div>
        <div className={styles.carouselPagination}>
          {photosList.map((_, index) => {
            return (
              <div
                key={`page-${index}`}
                className={`${styles.paginationDot} ${
                  index === currentIndex ? styles.paginationDotActive : ""
                }`}
                onClick={() => {
                  setCurrentIndex(index);
                }}
              ></div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default Gallery;
