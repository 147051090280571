import axios from "axios";
import { getCustomerToken } from "../../shared/helper";

const YOGA_BASE_URL = "https://api.yogawav.com";

export const loginMember = async (email, onSuccess, onFailure) => {
  try {
    const response = await axios.post(
      YOGA_BASE_URL + "/customer/login",
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );
    if (onFailure) {
      const errorMessage = error.response ? error.response.data : error.message;
      onFailure(errorMessage);
    }
  }
};

export const getInvoicesByCustomer = async (onSuccess, onFailure) => {
  try {
    const response = await axios.post(
      YOGA_BASE_URL + "/invoices/customer",
      {},
      {
        headers: {
          Authorization: `Bearer ${getCustomerToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );
    if (onFailure) {
      const errorMessage = error.response ? error.response.data : error.message;
      onFailure(errorMessage);
    }
  }
};

export const getInvoiceByID = async (onSuccess, onFailure) => {
  try {
    const response = await axios.post(
      YOGA_BASE_URL + "/invoice/670a1b4a43a4c1e356ada821",
      {},
      {
        headers: {
          Authorization: `Bearer ${getCustomerToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );
    if (onFailure) {
      const errorMessage = error.response ? error.response.data : error.message;
      onFailure(errorMessage);
    }
  }
};
