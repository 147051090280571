import AppDetails from "../app-details/AppDetails";
import QueryInput from "../query-input/QueryInput";
import Close from "../../../../assets/enquiry-form/close.svg";
import styles from "./EnquiryModal.module.css";
import { forwardRef } from "react";
import Logo from "../../../../assets/enquiry-form/logo.svg";

const EnquiryModal = forwardRef(({ onClose }, ref) => {
  return (
    <dialog className={styles.enquiryModal} ref={ref}>
      <div className={styles.modal}>
        <div className={styles.closeModal} onClick={() => ref.current.close()}>
          <button>
            {" "}
            <img src={Close} />
          </button>
        </div>
        <div className={styles.header}>
          <div>
            <img src={Logo} />
          </div>
          <div>
            <h3>Bliss Yoga</h3>
          </div>
        </div>
        <div className={styles.queryModal}>
          <div className={styles.appDetailsSection}>
            <AppDetails />
          </div>
          <div className={styles.queryInputSection}>
            <QueryInput />
          </div>
        </div>
      </div>
    </dialog>
  );
});

export default EnquiryModal;
