export const upcomingClassesList = [
  {
    date: "Today",
    start_time: "9.00 AM",
    end_time: "10.30 AM",
    title: "Beginners Yoga - Stage 1",
  },
  {
    date: "16 Oct, Wed",
    start_time: "9.00 AM",
    end_time: "10.30 AM",
    title: "Intermediate Yoga",
  },
  {
    date: "17 Oct, Thurs",
    start_time: "9.00 AM",
    end_time: "10.30 AM",
    title: "Advanced Yoga - Stage 1 - Session 32",
  },
];
