import { forwardRef } from "react";
import styles from "./MenuModal.module.css";
import { useRef } from "react";

import closeButton from "../../../../assets/close-button.svg";
import { FaPhoneAlt } from "react-icons/fa";
import EnquiryModal from "../../enquiry-form/enquiry-form-modal/EnquiryModal";

const MenuModal = forwardRef(function MenuModal(
  { servicesScroll, galleryScroll, testimonialScroll, batchesScroll },
  ref
) {
  const showEnquiryModal = useRef();

  function handleEnquiryClick() {
    showEnquiryModal.current.showModal();
  }

  function handleEnquiryModalClose() {
    showEnquiryModal.current.close();
  }

  return (
    <dialog className={styles.menuModalDialog} ref={ref}>
      <div className={styles.closeButton}>
        <button onClick={() => ref.current.close()}>
          <img src={closeButton} />
        </button>
      </div>
      <ul>
        <li onClick={() => ref.current.close()}>Home</li>
        <li onClick={servicesScroll}>Services</li>
        <li onClick={galleryScroll}>Gallery</li>
        <li onClick={testimonialScroll}>Testimonial</li>
        <li onClick={batchesScroll}>Batches</li>

        <li className={styles.actionButton}>
          <button onClick={handleEnquiryClick}>Enquiry</button>
        </li>
        <EnquiryModal
          ref={showEnquiryModal}
          onClose={handleEnquiryModalClose}
        />
        <li className={styles.actionButton}>
          <button>Sign up</button>
        </li>
        <li className={styles.call}>
          Call at{" "}
          <span>
            <FaPhoneAlt /> 9876543211
          </span>
        </li>
      </ul>
    </dialog>
  );
});

export default MenuModal;
