import Logo from "../../../../assets/enquiry-form/logo.svg";
import Image from "../../../../assets/enquiry-form/yogaImage.png";
import styles from "./AppDetails.module.css";

export default function AppDetails() {
  return (
    <div className={styles.appDetails}>
      <div className={styles.header}>
        <div>
          <img src={Logo} />
        </div>
        <div>
          <h3>Bliss Yoga</h3>
        </div>
      </div>
      <div className={styles.image}>
        <img src={Image} />
      </div>
      <div className={styles.description}>
        <h1>Bliss Yoga</h1>
        <p>
          Discover Your Inner Peace, Strengthen Your Body, and Elevate Your
          Spirit through.{" "}
        </p>
      </div>
    </div>
  );
}
