export const invoicesList = [
  {
    name: "John Peter Solomon",
    invoice_number: "S-I-001",
    date: "01 Aug 2023",
    amount: 500.0,
    mop: "cash",
    payment_status: "unpaid",
  },
  {
    name: "Claire Fraser",
    invoice_number: "S-I-002",
    date: "01 Aug 2023",
    amount: 500.0,
    mop: "online",
    payment_status: "unpaid",
  },
  {
    name: "Roger Mackenzie",
    invoice_number: "S-I-003",
    date: "01 Aug 2023",
    amount: 600.0,
    mop: "cash",
    payment_status: "unpaid",
  },
  {
    name: "Murtagh Fitzgibbons",
    invoice_number: "S-I-004",
    date: "13 Jan 2023",
    amount: 200.0,
    mop: "online",
    payment_status: "paid",
  },
];
