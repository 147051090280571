import { useNavigate } from "react-router-dom";
import { useRef } from "react";

import styles from "./Header.module.css";
import { FaPhoneAlt } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import EnquiryModal from "../enquiry-form/enquiry-form-modal/EnquiryModal";

export default function Header({
  businessDetails,
  servicesScroll,
  galleryScroll,
  testimonialScroll,
  batchesScroll,
  onOpen,
}) {
  const navigate = useNavigate();

  const showEnquiryModal = useRef(null);

  function handleEnquiryClick() {
    showEnquiryModal.current.showModal();
    console.log("show enquiry");
  }

  function handleEnquiryModalClose() {
    showEnquiryModal.current.close();
  }

  return (
    <div className={styles.header}>
      <div className={styles.logoTitle}>
        <img src={businessDetails.logo} alt="" />
        <p>{businessDetails.name}</p>
      </div>
      <div>
        <span className={styles.menuIcon} onClick={onOpen}>
          <GiHamburgerMenu size={30} color="white" />
        </span>
      </div>
      <div className={styles.menu}>
        <ul>
          <li style={{ color: "#496ce6" }}>Home</li>
          <li onClick={servicesScroll}>Services</li>
          <li onClick={galleryScroll}>Gallery</li>
          <li onClick={testimonialScroll}>Testimonials</li>
          <li onClick={batchesScroll}>Batches</li>
        </ul>
      </div>
      <div className={styles.actionButtons}>
        <button className={styles.enquiry} onClick={handleEnquiryClick}>
          Enquiry
        </button>
        <EnquiryModal
          ref={showEnquiryModal}
          onClose={handleEnquiryModalClose}
        />
        <button className={styles.signUp} onClick={() => navigate("/member")}>
          Sign in
        </button>
        <div className={styles.call}>
          Call at{" "}
          <span>
            <FaPhoneAlt /> {businessDetails.mobileNumber}
          </span>
        </div>
      </div>
    </div>
  );
}
