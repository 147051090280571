import axios from "axios";
import { BASE_URL, token } from "../../../shared/constants";

const authString = "Bearer ".concat(token);

export async function getAllBatches(onSuccess, onFailure) {
  try {
    const response = await axios.get(`${BASE_URL}/batches`, {
      headers: { Authorization: authString },
    });

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );
    if (onFailure) {
      const errorMessage = error.response ? error.response.data : error.message;
      onFailure(errorMessage);
    }
  }
}
