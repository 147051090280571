import styles from "./BatchesPage.module.css";
import Batches from "../../../layouts/Batches/Batches";
import { GiHamburgerMenu } from "react-icons/gi";
import { useRef } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import BatchesMenuModal from "../batches-menu/BatchesMenuModal";
import AboutUs from "../../../layouts/Footer/AboutUs";
import getLandingPageData from "../../../../services/helper/get_landing_page_data";

export default function BatchesPage() {
  const menuModalref = useRef();
  const landingPageData = getLandingPageData(window.pageData);

  const { business, socialLinks } = landingPageData;

  function onMenuModalClick() {
    menuModalref.current.showModal();
  }

  return (
    <div className={styles.batches}>
      <BatchesMenuModal isBatchesModal ref={menuModalref} />
      <div className={styles.header}>
        <div className={styles.appTitle}>
          <img src={business.logo} alt="" />
          <div>{business.name}</div>
        </div>
        <span className={styles.menu} onClick={onMenuModalClick}>
          <GiHamburgerMenu size={30} color="black" />
        </span>
        <div className={styles.actions}>
          <div className={styles.headerBtn}>
            <button>Enquiry</button>
            <button>Sign up</button>
          </div>
          <div className={styles.call}>
            Call at{" "}
            <span>
              <FaPhoneAlt /> {business.mobileNumber}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.batchesCard}>
        <Batches isBatchesPage />
      </div>
      <AboutUs businessDetails={business} socialLinks={socialLinks} />
    </div>
  );
}
