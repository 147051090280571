import image from "../../../assets/get-started.png";
import "./GetStarted.css";

export default function GetStarted() {
  return (
    <div className="get-started">
      <div className="get-started-content">
        <div className="title">
          Add digital wings to your yoga business with YogaWav
        </div>

        <div className="description">
          Expand your Yoga Services Business with the numerous solutions offered
          by this app. Elevate your yoga business to new heights.
        </div>

        <button className="get-started-btn">Get Started</button>
      </div>

      <img src={image} alt="" />
    </div>
  );
}
