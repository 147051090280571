import { useState } from "react";
import { useWindowWidth } from "../../../shared/helper";
import FullScreenModal from "../layouts/fullscreen-modal/FullscreenModal";
import InvoiceCard from "../layouts/mobile/invoice-card/InvoiceCard";
import InvoicesList from "../layouts/mobile/invoices-list/InvoicesList";
import ShowAll from "../layouts/helper/ShowAll";
import { invoicesList } from "../../data/invoices-data";
import Invoices from "./invoices/Invoices";

export default function InvoicesWrapperCard() {
  const [showModal, setShowModal] = useState(false);
  const { isMobile } = useWindowWidth();
  const recentInvoice = invoicesList.slice(-1)[0];

  const handleShowAll = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="invoices-wrapper-card">
      <div className="title-show-all">
        <div className="card-title-bold">Invoices</div>
        <ShowAll handleClick={handleShowAll} />
      </div>
      {isMobile ? (
        <InvoiceCard invoice={recentInvoice} isRecent={true} />
      ) : (
        <Invoices isRecent={true} />
      )}

      {showModal && (
        <FullScreenModal
          onClose={handleClose}
          title={"Invoices"}
          child={isMobile ? <InvoicesList /> : <Invoices />}
        />
      )}
    </div>
  );
}
