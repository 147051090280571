import styles from "./GoogleLoginPage.module.css";
import Logo from "../../../assets/dummy_logo.svg";
import yogaImage from "../../../assets/yogaImage.png";
import googleLogo from "../../../assets/googleLogo.svg";
import {useGoogleLogin} from '@react-oauth/google';
import axios from "axios";
import {Alert} from "@mui/material";
import {useState} from "react";

export default function GoogleLoginPage(props) {
    const [error, setError] = useState(false);
    const onGoogleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            console.log(tokenResponse);
            const userInfo = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo',
                {headers: {Authorization: `Bearer ${tokenResponse.access_token}`}},
            );
            if(userInfo){
                setError(false)
                props.onSuccess(userInfo.data);
            }else{
                setError(true)
            }
        },
        onError: (errorResponse) => {
            console.log(errorResponse)
            setError(true);
        }
    });

    return (
        <div className={styles.container}>
            <div className={styles.signIn}>
                <div className={styles.header}>
                    <div className={styles.logo}>
                        <img src={Logo} alt={"Logo"}/>
                    </div>
                    <div className={styles.title}>Blissyoga</div>
                </div>
                <div className={styles.image}>
                    <img src={yogaImage} alt={"Yoga"}/>
                </div>
                <div className={styles.description}>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                </div>
                <div className={styles.button} onClick={() => onGoogleLogin()}>
                    <img src={googleLogo} alt={"Google Logo"}/>
                    <div>Sign in with Google</div>
                </div>
                {error && (
                    <Alert severity="error" onClose={() => setError(false)}>
                        Something went wrong. Please try again.
                    </Alert>
                )}
                <div className={styles.terms}>
                    By sign in, you agree to Yogawav's <span>Terms of Use</span> and{" "}
                    <span>Privay Policy</span>
                </div>
            </div>
        </div>
    );
}
