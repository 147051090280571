import styles from "./QueryInput.module.css";

export default function QueryInput() {
  return (
    <div className={styles.query}>
      <div className={styles.details}>
        <h1>Ask your queries</h1>
        <div className={styles.description}>
          <p>
            Please use this enquiry form to share your contact details and let
            us know how we can assist you with any questions or information you
            need.
          </p>
        </div>
      </div>
      <form>
        <div className={styles.name}>
          <input placeholder="Name*" id="name" name="name" type="text" />
        </div>
        <div className={styles.emailId}>
          <input placeholder="Email ID*" id="email" name="email" type="email" />
        </div>
        <div className={styles.number}>
          <div className={styles.countryCode}>
            <select id="country-code" defaultValue="+91">
              <optgroup>
                <option value="+1"> +1</option>
                <option value="+44">+44</option>
                <option value="+66">+66</option>
                <option value="+91">+91</option>
              </optgroup>
            </select>
          </div>
          <div className={styles.mobileNumber}>
            <input
              placeholder="Mobile Number"
              id="mobileNumber"
              name="mobile number"
              type="tel"
            />
          </div>
        </div>
        <div className={styles.comments}>
          <textarea placeholder="Comments" />
        </div>
        <div className={styles.button}>
          <button className={styles.submit} type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
