import { FiArrowLeft } from "react-icons/fi";
import { useWindowWidth } from "../../../../shared/helper";
import styles from "./FullscreenModal.module.css";

export default function FullScreenModal({ onClose, title, child }) {
  const { isMobile } = useWindowWidth();

  return (
    <div className={styles.modalWrapper}>
      {isMobile ? (
        <div className={styles.modalHeaderMobile}>
          <FiArrowLeft className={styles.backArrow} onClick={onClose} />
          <span>{title}</span>
        </div>
      ) : (
        <div className={styles.modalHeaderWeb}>
          <div className={styles.backNavigation} onClick={onClose}>
            <FiArrowLeft className={styles.backArrow} />
            <span className={styles.backText}>Back</span>
          </div>
          <div className={styles.modalTitle}>{title}</div>
        </div>
      )}
      <div className={styles.modalBody}>{child}</div>
    </div>
  );
}
