import "./InvoiceCard.css";

export default function InvoiceCard({ invoice, isRecent }) {
  return (
    <div className={`dashboard-invoice-card ${isRecent ? "recent" : ""}`}>
      <div className="name-inv-no-row">
        <div className="name">{invoice.name}</div>
        <div className="inv-no">Inv. No.</div>
      </div>

      <div className="date-invoice-no-row">
        <div className="date">{invoice.date}</div>
        <div className="invoice-no">{invoice.invoice_number}</div>
      </div>

      <div className="payment-info-row">
        <div className="amount-mop-row">
          <div className="amount">₹{invoice.amount}</div>
          {invoice.payment_status !== "unpaid" && (
            <div className="mop">{invoice.mop}</div>
          )}
        </div>

        <button className={`payment-button ${invoice.payment_status}`}>
          {invoice.payment_status === "paid" ? "Download Receipt" : "Pay now"}
        </button>
      </div>
    </div>
  );
}
