import { useRef } from "react";
import { GiHamburgerMenu } from "react-icons/gi";

import logo from "../../../assets/startappify-logo.svg";
import MenuModal from "../menu/MenuModal";
import "./Header.css";

export default function Header() {
  const modalRef = useRef(null);

  function handleModalOpen() {
    modalRef.current.showModal();
  }

  function handleModalClose() {
    modalRef.current.close();
  }

  //todo: if window width is greater than 750px close modal

  return (
    <>
      <MenuModal ref={modalRef} onClose={handleModalClose} />
      <div className="header" id="header">
        <div className="brand-logo">
          <img src={logo} alt="" />
          <span>YogaWav</span>
        </div>

        <span className="menu-icon" onClick={handleModalOpen}>
          <GiHamburgerMenu />
        </span>

        <div className="menu">
          <ul>
            <li>TOS</li>
            <li>Privacy Policy</li>
            <li>Request Demo</li>
            <li>Contact Us</li>
          </ul>
        </div>
      </div>
    </>
  );
}
