import { useState } from "react";
import { useWindowWidth } from "../../../shared/helper";
import { batchesList } from "../../../beta-home/data/batches-data";
import FullScreenModal from "./fullscreen-modal/FullscreenModal";
import ShowAll from "./helper/ShowAll";
import Batches, { BatchCard } from "./batches/Batches";

export default function BatchesWrapperCard() {
  const { isMobile } = useWindowWidth();
  const [showModal, setShowModal] = useState(false);

  const handleShowAll = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const recentBatch = batchesList.slice(-1)[0];

  return (
    <div className="batches-wrapper-card">
      <div className="title-show-all">
        <div className="card-title-bold">Batches</div>
        {isMobile && <ShowAll handleClick={handleShowAll} />}
      </div>
      <BatchCard batch={recentBatch} isRecent={true} />

      {showModal && (
        <FullScreenModal
          onClose={handleClose}
          title={"Batches"}
          child={<Batches />}
        />
      )}
    </div>
  );
}
