import logo from "../../../assets/startappify-logo.svg";

import facebook from "../../../assets/yogawav-social/facebook.svg";
import instagram from "../../../assets/yogawav-social/instagram.svg";
import twitter from "../../../assets/yogawav-social/twitter.svg";
import youtube from "../../../assets/yogawav-social/youtube.svg";

import styles from "./Footer.module.css";

export default function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.content}>
        <div className={styles["brand-logo"]}>
          <img src={logo} alt="" />
          <span>YogaWav</span>
        </div>

        <div className={styles.address}>
          <span>NucleApp Software Private Limited</span>
          <pre>
            {
              "Awfis Space Solutions, \nKondapur \nHyderabad, Telangana, \nIndia -500084."
            }
          </pre>
          <a href="mailto:support@nucleapp.com">support@nucleapp.com</a>
        </div>

        <div className={styles.social}>
          <p>Connect with us</p>
          <div className={styles.socialIcons}>
            <img src={facebook} alt="Facebook" />
            <img src={instagram} alt="Instagram" />
            <img src={twitter} alt="Twitter" />
            <img src={youtube} alt="Youtube" />
          </div>
        </div>
      </div>
      <div className={styles.poweredBy}>Powered By NucleApp Pvt Ltd</div>
    </div>
  );
}
