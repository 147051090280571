import {useEffect, useState} from "react";

export function formatDateTime(millis) {
    const now = new Date();
    const date = new Date(Number(millis));

    const timeDiff = now.getTime() - date.getTime();
    const oneHour = 60 * 60 * 1000;
    const oneDay = 24 * 60 * 60 * 1000;

    const isToday = now.toDateString() === date.toDateString();
    const isYesterday =
        new Date(now.getTime() - oneDay).toDateString() === date.toDateString();

    if (timeDiff < oneHour && isToday) {
        // If within the past one hour
        const minutesAgo = Math.floor(timeDiff / (60 * 1000));
        return `${minutesAgo} mins ago`;
    }

    if (isToday) {
        // If it is today, return the time in "12:43 PM" format
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const ampm = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12;
        return `Today ${formattedHours}:${minutes} ${ampm}`;
    }

    if (isYesterday) {
        return "Yesterday";
    }

    // For dates older than yesterday, return in "20 Dec 2024" format
    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("default", {month: "short"});
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
}

export function useWindowWidth() {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 750;

    return {width, isMobile};
}

const MEMBER_DATA_KEY = "6720b75358c9a35";

export function getMemberDataLocal() {
    try {
        return JSON.parse(atob(localStorage.getItem(MEMBER_DATA_KEY)));
    } catch (e) {
        return null;
    }
}

export const getCustomerToken = () => {
    try {
        return getMemberDataLocal().token;
    } catch (e) {
        return null;
    }
};

export const getCustomerDBID = () => {
    try {
        return getMemberDataLocal().customer_db_id;
    } catch (e) {
        return null;
    }
};


export function setMemberDataLocal(memberData, encode = true) {
    const memberDataString = encode ? btoa(memberData) : memberData;
    localStorage.setItem(MEMBER_DATA_KEY, memberDataString);
    return memberDataString;
}


