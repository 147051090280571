import { forwardRef } from "react";
// import { MdClose } from "react-icons/md";
import { IoArrowBack } from "react-icons/io5";

import "./AllTestimonials.css";
import { TestimonialCard } from "../../Testimonials/Testimonials";

const AllTestimonials = forwardRef(function AllTestimonials(
  { onClose, testimonialsList },
  ref
) {
  return (
    <>
      <dialog ref={ref} className="all-testimonials">
        <div className="testimonials-modal-container">
          {/* <div onClick={onClose} className="close">
            <MdClose />
          </div> */}

          <div className="back-btn" onClick={onClose}>
            <IoArrowBack />
          </div>
          <div className="title">Testimonials</div>

          <ul>
            {testimonialsList.map((item, index) => {
              return (
                <li key={`testimonials-${index}`}>
                  <TestimonialCard
                    imageUrl={item.image_url || ""}
                    title={item.title || ""}
                    desc={item.description || ""}
                    name={item.name || ""}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </dialog>
    </>
  );
});

export default AllTestimonials;
