import batch1 from "../assets/batches/batch1.png";
import batch2 from "../assets/batches/batch2.png";
import batch3 from "../assets/batches/batch3.png";

export const batchesList = [
  {
    title: "Yoga for Beginners",
    description:
      "Welcome to our Beginner Yoga class! This class is designed for those who are new to yoga or wish to revisit the basics.",
    startTime: "09.00 AM",
    endTime: "11.00 AM",
    price: "499",
    url: batch1,
  },
  {
    title: "Kundalini Yoga",
    description:
      "Welcome to our Beginner Yoga class! This class is designed for those who are new to yoga or wish to revisit the basics.",
    startTime: "11.30 AM",
    endTime: "01.00 PM",
    price: "999",
    url: batch2,
  },
  {
    title: "Breathing Techniques",
    description:
      "Welcome to our Beginner Yoga class! This class is designed for those who are new to yoga or wish to revisit the basics.",
    startTime: "04.00 PM",
    endTime: "05.00 PM",
    price: "399",
    url: batch3,
  },
];
