import { GoClockFill } from "react-icons/go";
import { batchesList } from "../../../../beta-home/data/batches-data";

import "./Batches.css";

export default function Batches() {
  return (
    <div className="dashboard-batches">
      <ul>
        {batchesList.map((batch, index) => {
          return (
            <li key={`batch-${index}`}>
              <BatchCard batch={batch} />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export function BatchCard({ batch, isRecent }) {
  return (
    <div className={`dashboard-batch-card ${isRecent ? "recent" : ""}`}>
      <img src={batch.url} alt="" />
      <div className="batch-content">
        <span className="title">{batch.title}</span>
        <hr />
        <span className="desc">{batch.description}</span>
        <div className="timing">
          <GoClockFill className="clock-icon" />
          <span>Timing: {batch.startTime}</span>
        </div>
        <button>Join now</button>
      </div>
    </div>
  );
}
