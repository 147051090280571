export function millisToDate(millis) {
    const date = new Date(millis);

    const pad = (number) => String(number).padStart(2, "0");

    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1);
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
}

export function formatAmount(amount) {
    if (amount) {
        const formattedAmount = amount.toLocaleString("en-IN", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return `₹${formattedAmount}`;
    }
    return `0`;
}

export function formatNumber(number) {
    const pad = (number) => String(number).padStart(2, "0");
    return pad(number);
}
