import Features from "../layouts/features/Features";
import Footer from "../layouts/footer/Footer";
import GetStarted from "../layouts/landing/GetStarted";
import Header from "../layouts/header/Header";

export default function Home() {
  return (
    <>
      <main>
        <Header />
        <GetStarted />
        <Features />
        <Footer />
      </main>
    </>
  );
}
