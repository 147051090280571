import { invoicesList } from "../../../../data/invoices-data";
import InvoiceCard from "../invoice-card/InvoiceCard";
import "./InvoicesList.css";

export default function InvoicesList() {
  return (
    <div className="mobile-dashboard-invoices-list">
      <ul>
        {invoicesList.map((invoice, index) => {
          return (
            <li key={`invoice-${index}`}>
              <InvoiceCard invoice={invoice} />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
