import { upcomingClassesList } from "../../../data/upcoming-classes-data";
import { useWindowWidth } from "../../../../shared/helper";
import "./UpcomingClasses.css";

export default function UpcomingClasses() {
  const { isMobile } = useWindowWidth();

  return (
    <div className="upcoming-classes-card">
      <div className="card-title-bold">Upcoming Classes</div>

      <div className="upcoming-classes-list">
        {upcomingClassesList.map((item, index) => (
          <div className="upcoming-classes-list-item" key={index}>
            {!isMobile ? (
              <>
                <div className="date">{item.date}</div>
                <div className="title">{item.title}</div>
                <div className="time">
                  {item.start_time} - {item.end_time}
                </div>
              </>
            ) : (
              <>
                <div className="time-and-date">
                  <div className="time">
                    {item.start_time} - {item.end_time}
                  </div>
                  <div className="date">{item.date}</div>
                </div>
                <div className="title">{item.title}</div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
