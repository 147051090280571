import { ShowAll } from "../../../../shared/components/utils/utils";
import { GoClockFill } from "react-icons/go";
import styles from "./Batches.module.css";
import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAllBatches } from "../../../services/network/get-batches-api.js";
import CircularProgress from "@mui/material/CircularProgress";

const Batches = forwardRef(function Batches({ isBatchesPage }, ref) {
  const [batchesData, setBatchesData] = useState([]);
  const [loading, setLoading] = useState(true);

  let className = styles.batches;
  if (isBatchesPage) {
    className += ` ${styles.batchesPage}`;
  } else {
    className += "";
  }

  const navigate = useNavigate();

  useEffect(() => {
    getAllBatches(
      (response) => {
        setBatchesData(response.batches);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <>
      {loading ? (
        <div className="styles.loaderWrapper">
          <CircularProgress thickness={4} />
        </div>
      ) : (
        <div ref={ref} className={className}>
          <>
            {isBatchesPage ? (
              <>
                <div className={styles.titleHeader}>
                  <div>Batches</div>
                </div>
                <ul>
                  {batchesData.map((batch, index) => {
                    return (
                      <li key={`batch-${index}`}>
                        <BatchCard
                          batchData={batch}
                          isBatchesPageCard={isBatchesPage}
                        />
                      </li>
                    );
                  })}
                </ul>
              </>
            ) : (
              <>
                <div className={styles.titleHeader}>
                  <h2>Batches</h2>
                  <span
                    className={`${
                      batchesData.length > 3
                        ? `${styles.showAll}`
                        : `${styles.noShowAll}`
                    }`}
                    onClick={() => navigate("/batches")}
                  >
                    <ShowAll />
                  </span>
                </div>
                <ul>
                  {batchesData.map((batch, index) => {
                    return (
                      index < 3 && (
                        <li key={`batch-${index}`}>
                          <BatchCard
                            batchData={batch}
                            isBatchesPageCard={isBatchesPage}
                          />
                        </li>
                      )
                    );
                  })}
                </ul>
              </>
            )}
          </>
        </div>
      )}
    </>
  );
});

function BatchCard({ batchData, isBatchesPageCard }) {
  let className = styles.batchCard;
  if (isBatchesPageCard) {
    className += ` ${styles.batchesPage}`;
  } else {
    className += "";
  }

  return (
    <div className={className}>
      {isBatchesPageCard ? (
        <div className="batchesImage">
          {" "}
          <img src={batchData.image_url} alt="" />
        </div>
      ) : (
        <img src={batchData.image_url} alt="" />
      )}

      <div className={styles.batchContent}>
        <span className={styles.title}>{batchData.general_details.title}</span>
        <hr />
        <span className={styles.desc}>
          {batchData.general_details.description}
        </span>
        <div className={styles.timing}>
          <GoClockFill color="#496CE6" />
          <span>
            <span className={styles.label}>Timing:</span> {"10.00"} to {"10.30"}
          </span>
        </div>
        <span className={styles.price}>
          {batchData.pricing.currency === "inr" ? "₹ " : "$ "}
          {batchData.pricing.payable_amount}
        </span>
        <button>Book now</button>
      </div>
    </div>
  );
}

export default Batches;
