import { forwardRef, useRef, useState } from "react";
import ServiceModal from "../Service-Modal/ServiceModal";
import { IoArrowBack } from "react-icons/io5";

import "./AllServices.css";
import { ServiceCard } from "../../../Services/Services";

const AllServices = forwardRef(function AllServices(
  { onClose, servicesList },
  ref
) {
  const [selectedService, setSelectedService] = useState(null);
  const serviceModalRef = useRef(null);

  function handleModalOpen(service) {
    // serviceModalRef.current.inert = true;
    serviceModalRef.current.showModal();
    // serviceModalRef.current.inert = false;
    setSelectedService(service);
  }

  function handleModalClose() {
    serviceModalRef.current.close();
  }
  return (
    <>
      <ServiceModal
        ref={serviceModalRef}
        onClose={handleModalClose}
        service={selectedService != null ? selectedService : {}}
      />
      <dialog ref={ref} className="all-services">
        <div className="services-modal-container">
          <div className="back-btn" onClick={onClose}>
            <IoArrowBack />
          </div>

          <div className="title">Our Services & Customer Benefits</div>

          <ul>
            {servicesList.map((service, index) => {
              return (
                <li
                  key={`service-${index}`}
                  onClick={() => handleModalOpen(service)}
                >
                  <ServiceCard
                    imageUrl={service.image_url || ""}
                    title={service.title || ""}
                    desc={service.description || ""}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </dialog>
    </>
  );
});

export default AllServices;
