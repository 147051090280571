import { MdKeyboardArrowRight } from "react-icons/md";

export default function ShowAll({ handleClick }) {
  return (
    <div className="show-all" onClick={handleClick}>
      <span>Show All</span>
      <MdKeyboardArrowRight className="arrow-icon" />
    </div>
  );
}
