import instagramIcon from "../../../assets/social/instagram.svg";
import facebookIcon from "../../../assets/social/facebook.svg";
import twitterIcon from "../../../assets/social/twitter.svg";
import linkedinIcon from "../../../assets/social/linkedin.svg";
import youtubeIcon from "../../../assets/social/youtube.svg";

import styles from "./AboutUs.module.css";

export default function AboutUs({ businessDetails, socialLinks }) {
  function openLink(url) {
    if (url) {
      window.open(url, "_blank", "noopener, noreferrer");
    }
  }
  return (
    <footer style={{ marginTop: "0" }}>
      <div className={styles["about-us"]}>
        <div className={styles["app-logo-title"]}>
          <img src={businessDetails.logo} alt="App Logo" />
          <span>{businessDetails.name}</span>
        </div>
        <div className={styles["address-div"]}>
          <span className={styles["app-name"]}>{businessDetails.name}</span>
          <span className={styles.address}>{businessDetails.address}</span>
          <a href="mailto:support@nucleapp.com" className={styles.email}>
            {businessDetails.email}
          </a>
        </div>
        <div className={styles.social}>
          <span>Know us better</span>
          <div className={styles["social-icons"]}>
            <img
              src={instagramIcon}
              alt="Instagram"
              onClick={() => openLink(socialLinks.instagram)}
            />
            <img
              src={facebookIcon}
              alt="Facebook"
              onClick={() => openLink(socialLinks.facebook)}
            />
            <img
              src={twitterIcon}
              alt="Twitter"
              onClick={() => openLink(socialLinks.twitter)}
            />
            <img
              src={linkedinIcon}
              alt="LinkedIn"
              onClick={() => openLink(socialLinks.linkedin)}
            />
            <img
              src={youtubeIcon}
              alt="YouTube"
              onClick={() => openLink(socialLinks.youtube)}
            />
          </div>
        </div>
      </div>
      <p className={styles["powered-by"]}>Powered by NucleApp</p>
    </footer>
  );
}
