import { formatAmount, formatNumber } from "../../../helper";
import "./invoice-table.css";

export default function InvoiceTable({ invoiceData }) {
  const items = invoiceData.items;

  return (
    <div className="invoice-table">
      <table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Item</th>
            <th>CGST</th>
            <th>SGST</th>
            <th>IGST</th>
            <th>HSN</th>
            <th>Qty</th>
            <th>Rate</th>
            <th>Amount</th>
          </tr>
        </thead>

        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}.</td>
              <td>{item.item_name}</td>
              <td>
                {item.cgst_percentage !== null
                  ? `${item.cgst_percentage}%`
                  : ""}
              </td>
              <td>
                {item.sgst_percentage !== null
                  ? `${item.sgst_percentage}%`
                  : ""}
              </td>
              <td>
                {item.igst_percentage !== null
                  ? `${item.igst_percentage}%`
                  : ""}
              </td>
              <td>{item.hsn_code !== null ? item.hsn_code : ""}</td>
              <td>{formatNumber(item.quantity)}</td>
              <td>{formatAmount(item.unit_price)}</td>
              <td>{formatAmount(item.quantity * item.unit_price)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
