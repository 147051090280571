import { forwardRef, useRef, useState } from "react";
// import { MdClose } from "react-icons/md";
import { IoArrowBack } from "react-icons/io5";

import "./AllWhyUs.css";
import WhyUsModal from "../WhyUsModal/WhyUsModal";
import { WhyUsCard } from "../../../WhyUs/WhyUs";

const AllWhyUs = forwardRef(function AllWhyUs({ onClose, whyUsList }, ref) {
  const [selectedWhyUs, setSelectedWhyUs] = useState(null);
  const whyUsModalRef = useRef(null);

  function handleModalOpen(whyUs) {
    whyUsModalRef.current.showModal();
    setSelectedWhyUs(whyUs);
  }

  function handleModalClose() {
    whyUsModalRef.current.close();
  }

  return (
    <>
      <WhyUsModal
        ref={whyUsModalRef}
        onClose={handleModalClose}
        whyUs={selectedWhyUs != null ? selectedWhyUs : {}}
      />
      <dialog ref={ref} className="all-why-us">
        <div className="all-why-us-modal-container">
          {/* <div onClick={onClose} className="close">
            <MdClose />
          </div> */}

          <div className="back-btn" onClick={onClose}>
            <IoArrowBack />
          </div>
          <div className="title">Why Choose Us</div>

          <ul>
            {whyUsList.map((whyUs, index) => {
              return (
                <li
                  key={`whyUs-${index}`}
                  onClick={() => handleModalOpen(whyUs)}
                >
                  <WhyUsCard
                    imageUrl={whyUs.image_url || ""}
                    title={whyUs.title || ""}
                    desc={whyUs.description || ""}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </dialog>
    </>
  );
});

export default AllWhyUs;
