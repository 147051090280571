import whatsapp from "../../../assets/social/mobile/whatsapp.svg";
import facebook from "../../../assets/social/mobile/facebook.svg";
import instagram from "../../../assets/social/mobile/instagram.svg";
import gmail from "../../../assets/social/mobile/gmail.svg";
import twitter from "../../../assets/social/mobile/twitter.svg";
import share from "../../../assets/social/mobile/share.svg";

import "./InviteFriendsCard.css";

export default function InviteFriendsCard() {
  return (
    <div className="invite-friends-card">
      <div className="card-title-bold">Invite Friends</div>
      <div className="social-icons-row">
        <SocialIcon platform={whatsapp} />
        <SocialIcon platform={facebook} />
        <SocialIcon platform={instagram} />
        <SocialIcon platform={gmail} />
        <SocialIcon platform={twitter} />
        <SocialIcon platform={share} />
      </div>
    </div>
  );
}

function SocialIcon({ platform }) {
  return (
    <div className="social-icon">
      <img src={platform} alt="" />
    </div>
  );
}
