import CostSummary from "../../layouts/cost-summary/cost-summary";
import InvoiceTable from "../../layouts/invoice-table/invoice-table";
import logo from "../../../assets/images/logo.svg";

import { millisToDate } from "../../../helper";
import "./invoice.css";
import { useEffect, useRef, useState } from "react";
import { getInvoiceByID } from "../../../../member-dashboard/services/profile-page-api";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { formatDateTime } from "../../../../shared/helper";

const buttonStyle = {
  fontFamily: "inherit",
  fontSize: "0.9vw",
  fontWeight: 700,
  padding: "0.4vw 1vw",
  borderRadius: "50px",
  cursor: "pointer",
  textAlign: "center",
  width: "200px",
  height: "50px",
  backgroundColor: "#0c66e4",
  color: "white",
  border: "none",
};

export default function Invoice() {
  const invoiceComponentRef = useRef();
  const [invoiceData, setInvoiceData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  useEffect(() => {
    // Fetch the invoices data from API
    getInvoiceByID(
      (response) => {
        setInvoiceData(response.invoice);
        console.log(response);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }, []);

  const handleDownloadPDF = () => {
    setIsGeneratingPDF(true);
    html2canvas(invoiceComponentRef.current, { scale: 1 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 0.75);
      const pdf = new jsPDF();
      const imgWidth = 192; // Adjust as necessary
      const pageHeight = pdf.internal.pageSize.height;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, "JPEG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save("invoice_" + invoiceData._id + ".pdf");
      setIsGeneratingPDF(false);
    });
  };

  return (
    <>
      <main className="invoice-wrapper">
        {isLoading ? (
          <div className="loading">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            <div className="invoice-container" ref={invoiceComponentRef}>
              <div className="block-line"></div>

              <div className="invoice-content">
                <div className="brand-logo">
                  <img src={logo} alt="" />
                  <span>Bliss Yoga</span>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="invoice-number">
                    INVOICE {invoiceData.invoice_number}
                  </div>
                  <button
                    style={{
                      ...buttonStyle,
                      display: isGeneratingPDF ? "none" : "block",
                    }}
                    onClick={handleDownloadPDF}
                  >
                    Download
                  </button>
                </div>

                <div className="basic-info">
                  <div className="gst">
                    GST: <span className="bold">{"JF7A4NFSV78"}</span>
                  </div>

                  <div className="date">
                    DATE:{" "}
                    <span className="bold">
                      {formatDateTime(invoiceData.date)}
                    </span>
                  </div>
                </div>

                <div className="invoice-address-div">
                  <div className="from">
                    <div className="from-to">From</div>
                    <div className="name">Bliss Yoga</div>
                    <div className="address">
                      5, Avva Street, Mount Road, Chennai 623089
                    </div>
                    <div className="mobile">+91 9876541230</div>
                    <div className="email">blissyoga33@gmail.com</div>
                  </div>

                  <div className="to">
                    <div className="from-to">To</div>
                    <div className="name">Chandra Bose S</div>
                    <div className="address">23, Usman Road, Mumbai 656564</div>
                    <div className="mobile">+91 9876541230</div>
                    <div className="email">chandrabose4@gmail.com</div>
                  </div>
                </div>

                <div className="payment-reference">
                  Receipt or Payment reference:{" "}
                  <span>{invoiceData.receipt_payment_reference ?? "-"}</span>
                </div>

                <InvoiceTable invoiceData={invoiceData} />

                <CostSummary invoiceData={invoiceData} />

                <div className="signature">
                  <span>With Regards,</span>
                  <img src="" alt="" />
                </div>
              </div>

              <div className="invoice-footer">
                <div className="thank-you">Thank you!</div>

                <div className="powered-by">Powered by YogaWav</div>
              </div>
            </div>
          </>
        )}
      </main>
    </>
  );
}
