// export const token =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5kZXhJZCI6IjY2YTRkZTU3OTQwY2E1ZWMzYzYyNjJmNiIsImlhdCI6MTcyMjI0MjU0N30.LwFMMvReRrt8sgNGXfH4kguRvI2G09DJHaOx1Q6tIa4";

export const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5kZXhJZCI6IjY3MmEzMTMwMDM1OTQ3YjAzNzU5ZjllYSIsImlhdCI6MTczMDgxODQ2OX0.mtxBAXVAIG5XhHAuu9_xyf7UD4ACcjN2-3liraTDyKU";

export const PAGE_TYPE = {
  YOGA_WAV_HOME: "YOGA_WAV_HOME",
  ALPHA_LANDING: "ALPHA_LANDING",
  AUTH: "AUTH",
};

export const BASE_URL = "https://api.yogawav.com";
