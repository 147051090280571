import { useEffect, useRef, useState } from "react";
import { MdLocalPhone } from "react-icons/md";
import logo from "../../../../shared/assets/logo.svg";
import customer from "../../../assets/customer.png";
import "./Header.css";
import AccountPopup from "../account-popup/AccountPopup";

export default function Header(props) {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);

  function togglePopup() {
    setShowPopup(!showPopup);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="dashboard-header">
      <div className="brand-logo">
        <img src={logo} alt="" />
        <span>Bliss Yoga</span>
      </div>

      <div className="contact-us-details">
        <div className="call-at">
          <span>Call at</span>
          <div className="phone-number">
            <MdLocalPhone />
            <span>9876543211</span>
          </div>
        </div>
        <img
          src={customer}
          alt=""
          className="customer-image"
          onClick={togglePopup}
        />
        {showPopup && (
          <AccountPopup {...props} ref={popupRef} onClose={togglePopup} />
        )}
      </div>
    </div>
  );
}
