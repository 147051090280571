import styles from "./Invoices.module.css";
import { useEffect, useState } from "react";
import { getInvoicesByCustomer } from "../../../services/profile-page-api";
import { formatDateTime } from "../../../../shared/helper";
import CircularProgress from "@mui/material/CircularProgress";

export default function Invoices({ isRecent }) {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the invoices data from API
    getInvoicesByCustomer(
      (response) => {
        setInvoices(isRecent ? response.invoices.slice(-3) : response.invoices);
        console.log(response);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, [isRecent]);

  return (
    <>
      {loading ? (
        <div className="loader-wrapper">
          <CircularProgress variant="soft" thickness={4} />
        </div>
      ) : (
        <div
          className={`${styles.invoicesList} ${isRecent ? styles.recent : ""}`}
        >
          <div className={styles.headerRow}>
            <TableHeader text="Invoice" />
            <TableHeader text="Date" />
            <TableHeader text="Amount" />
            <TableHeader text="Mode of payment" />
            <TableHeader text="Download / Pay" />
          </div>
          {invoices.map((invoice, index) => (
            <div className={styles.dataRow} key={index}>
              <TableData data={invoice.invoice_number} />
              <TableData data={formatDateTime(invoice.date)} />
              <TableData data={invoice.total_amount} />
              <TableData data={invoice.mode_of_payment} />
              <TableData data="payment" paymentStatus={invoice.paymentStatus} />
            </div>
          ))}
        </div>
      )}
    </>
  );
}

function TableHeader({ text }) {
  return <div className={styles.tableHeader}>{text}</div>;
}

function TableData({ data, paymentStatus }) {
  return (
    <div className={styles.tableData}>
      {data !== "payment" ? data : <Payment paymentStatus={paymentStatus} />}
    </div>
  );
}

function Payment({ paymentStatus }) {
  return (
    <div className={styles.payment}>
      {paymentStatus === "paid" ? (
        <button className={styles.payNow}>Pay Now</button>
      ) : (
        <button className={styles.downloadReceipt}>Download Receipt</button>
      )}
    </div>
  );
}
