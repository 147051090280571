export default function ContactUsCard() {
  return (
    <div className="contact-us-card">
      <div className="card-title-bold">Contact us</div>
      <span>
        If you have any questions or need assistance, feel free to reach out to
        us. We're here to help!
      </span>
      <button className="get-support-btn">Get Support</button>
    </div>
  );
}
