import { forwardRef } from "react";
import { MdClose } from "react-icons/md";
import "./MenuModal.css";

const MenuModal = forwardRef(function MenuModal({ onClose }, ref) {
  return (
    <dialog ref={ref} id="menu-modal">
      <div className="modal-container">
        <ul className="menu-items">
          <li onClick={onClose}>Home</li>
          <li>TOS</li>
          <li>Privacy Policy</li>
          <li>Request Demo</li>
          <li>Contact Us</li>
          <li onClick={onClose} className="close">
            <MdClose />
          </li>
        </ul>
      </div>
    </dialog>
  );
});

export default MenuModal;
