import { forwardRef } from "react";
import closeButton from "../../../../assets/close-button.svg";
import styles from "./BatchesMenuModal.module.css";
import call from "../../../../assets/phoneCall.svg";

const BatchesMenuModal = forwardRef(function BatchesMenuModal(
  {
    servicesScroll,
    galleryScroll,
    testimonialScroll,
    batchesScroll,
    serviceReqScroll,
  },
  ref
) {
  return (
    <dialog className={styles.batchesMenuModal} ref={ref}>
      <div className={styles.closeButton}>
        <button onClick={() => ref.current.close()}>
          <img src={closeButton} />
        </button>
      </div>
      <ul>
        <li>Home</li>
        <li onClick={servicesScroll}>Services</li>
        <li>Gallery</li>
        <li>Testimonial</li>
        <li>Batches</li>
        <li>Service Request</li>
        <li className={styles.actionButton}>
          <button>Enquiry</button>
        </li>
        <li className={styles.actionButton}>
          <button>Sign up</button>
        </li>
        <li className={styles.call}>
          Call at{" "}
          <span>
            <img src={call} /> 9876543211
          </span>
        </li>
      </ul>
    </dialog>
  );
});

export default BatchesMenuModal;
