import {
  getMemberDataLocal,
  setMemberDataLocal,
  useWindowWidth,
} from "../../../../shared/helper";
import "./Dashboard.css";

import UpcomingClasses from "../../layouts/upcoming-classes/UpcomingClasses";
import BatchesWrapperCard from "../../layouts/BatchesWrapperCard";
import InvoicesWrapperCard from "../../layouts/InvoicesWrapperCard";
import ContactUsCard from "../../layouts/ContactUsCard";
import InviteFriendsCard from "../../layouts/invite-friends-card/InviteFriendsCard";
import AboutUs from "../../../../beta-home/components/layouts/Footer/AboutUs";
import { useLocation } from "react-router-dom";
import Header from "../../layouts/Header/Header";

export default function MemberDashboard() {
  const { isMobile } = useWindowWidth();
  const { search } = useLocation();
  const authData = new URLSearchParams(search).get("auth");
  setMemberDataLocal(authData, false);
  // window.history.pushState({}, '', window.location.pathname);
  const memberData = getMemberDataLocal();
  if (!memberData) {
    if (window.location.hostname.includes("localhost")) {
      window.location.href = `http://localhost:3000/auth`;
    } else {
      window.location.href = `https://yogawav.com/auth`;
    }
    return null;
  }
  return (
    <div className="member-dashboard-wrapper">
      <div
        className={isMobile ? "mobile-member-dashboard" : "member-dashboard"}
      >
        <Header memberData={memberData} />
        <div className="content-area">
          <UpcomingClasses />
          <BatchesWrapperCard />
          <InvoicesWrapperCard />
          <ContactUsCard />
          <InviteFriendsCard />
        </div>
        <AboutUs />
      </div>
    </div>
  );
}
