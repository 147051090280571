import { forwardRef } from "react";
import { MdClose } from "react-icons/md";
import styles from "./WhyUsModal.module.css";

const WhyUsModal = forwardRef(function WhyUsModal({ onClose, whyUs }, ref) {
  return (
    <dialog ref={ref} className={styles.whyUsModal}>
      <div className={styles.whyUsModalContainer}>
        <div onClick={onClose} className={styles.close}>
          <MdClose />
        </div>
        <div className={styles.modalContent}>
          <img src={whyUs.image_url || ""} alt="" />
          <div className={styles.title}>{whyUs.title}</div>
          <div className={styles.desc}>{whyUs.description}</div>
        </div>
      </div>
    </dialog>
  );
});

export default WhyUsModal;
