import { forwardRef } from "react";
import customer from "../../../assets/customer.png";
import styles from "./AccountPopup.module.css";
import { IoMdClose } from "react-icons/io";
import { googleLogout } from '@react-oauth/google';
import {setMemberDataLocal} from "../../../../shared/helper";

const AccountPopup = forwardRef(function AccountPopup({ onClose, memberData, setMemberData}, ref) {
  function handleGoogleLogout() {
    googleLogout()
    setMemberDataLocal()
    if (window.location.hostname.includes("localhost")) {
      window.location.href = `http://localhost:5001/auth`;
    } else {
      window.location.href = `https://yogawav.com/auth`;
    }
  }
  return (
    <div className={styles.popupOverlay}>
      <div className={styles.mainContainer} ref={ref}>
        <IoMdClose className={styles.closeBtn} onClick={onClose} />
        <div className={styles.popupHeader}>Account Info</div>
        <div className={styles.details}>
          <img src={customer} alt="" />

          <span className={styles.name}>John Peter</span>
          <span>Customer ID: S-C-0001</span>
          <span>Joined on: 10 May 2024</span>
          <span>sjohnpeter@gmail.com</span>
        </div>
        <button className={styles.signoutBtn} onClick={handleGoogleLogout}>Sign Out</button>
      </div>
    </div>
  );
});

export default AccountPopup;
